import chameleonCard from "../images/Chameleon Card.png";
import ChameleonIcoBlue from "../images/Chameleon Icon Blue.png";
import ChameleoniconGreen from "../images/Chameleon icon Green.png";
import Chameleon from "../images/Chameleon.png";
import FillSpace from "../images/Fill Space.png";
import MobileBG from "../images/Mobile BG.png";
import SecretWordCard from "../images/Secret Word Card.png";
import VoteButton from "../images/Vote Button.png";
import YellowBox from "../images/YellowBox.png";
import BG from "../images/Host/BG.png";
import ButtonBG1 from "../images/Host/Button BG_1.png";
import ButtonBG2 from "../images/Host/Button BG_2.png";
import ChameleonPodiumLeft2 from "../images/Host/Chameleon_Podium_Left_2.png";
import ChameleonPodiumLeft3 from "../images/Host/Chameleon_Podium_Left_3.png";
import ChameleonPodiumMiddle from "../images/Host/Chameleon_Podium_Middle.png";
import ChameleonPodiumRight1 from "../images/Host/Chameleon_Podium_Right_1.png";
import ChameleonPodiumRight2 from "../images/Host/Chameleon_Podium_Right_2.png";
import ChameleonPodiumRight3 from "../images/Host/Chameleon_Podium_Right_3.png";
import characterinfo from "../images/Host/character info.png";
import Correctstamp from "../images/Host/Correct_stamp.png";
import Logo from "../images/Host/Logo.png";
import Postitcopy2 from "../images/Host/Post it copy 2.png";
import Postit from "../images/Host/Post it.png";
import Potatonamebar from "../images/Host/Potato name bar.png";
import Scoreboard from "../images/Host/Scoreboard.png";
import scrawlfullscreen from "../images/Host/scrawl_fullscreen.png";
import scrawlhelp from "../images/Host/scrawl_help.png";
import scrawlmuted from "../images/Host/scrawl_muted.png";
import scrawlunmuted from "../images/Host/scrawl_unmuted.png";
import Votedstamp from "../images/Host/Voted_stamp.png";
import wordreveal from "../images/Host/word reveal.png";
import WrongStamp from "../images/Host/Wrong_Stamp.png";
import FolderBack from "../images/Host/Folder/Folder_Back.png";
import FolderCard from "../images/Host/Folder/Folder_Card.png";
import FolderFront from "../images/Host/Folder/Folder_Front.png";
import FolderPaperclip from "../images/Host/Folder/Folder_Paperclip.png";
import FolderRevealCard from "../images/Host/Folder/Folder_Reveal Card.png";
import FolderRound1 from "../images/Host/RoundNumbers/Folder_Round 1.png";
import FolderRound2 from "../images/Host/RoundNumbers/Folder_Round 2.png";
import FolderRound3 from "../images/Host/RoundNumbers/Folder_Round 3.png";
import FolderRound4 from "../images/Host/RoundNumbers/Folder_Round 4.png";
import FolderRound5 from "../images/Host/RoundNumbers/Folder_Round 5.png";
import FolderRound6 from "../images/Host/RoundNumbers/Folder_Round 6.png";
import FolderRound7 from "../images/Host/RoundNumbers/Folder_Round 7.png";
import FolderRound8 from "../images/Host/RoundNumbers/Folder_Round 8.png";
import Mugshot_Bunny from "../images/Mugshots/Mugshot_Bunny.png";
import Mugshot_Cat from "../images/Mugshots/Mugshot_Cat.png";
import Mugshot_Chicken from "../images/Mugshots/Mugshot_Chicken.png";
import Mugshot_CountBarabula from "../images/Mugshots/Mugshot_CountBarabula.png";
import Mugshot_Cowboy from "../images/Mugshots/Mugshot_Cowboy.png";
import Mugshot_EasterEgg from "../images/Mugshots/Mugshot_EasterEgg.png";
import Mugshot_Elf from "../images/Mugshots/Mugshot_Elf.png";
import Mugshot_FrankensteinsPotato from "../images/Mugshots/Mugshot_FrankensteinsPotato.png";
import Mugshot_Gingie from "../images/Mugshots/Mugshot_Gingie.png";
import Mugshot_InvisiblePotato from "../images/Mugshots/Mugshot_InvisiblePotato.png";
import Mugshot_MadamePotat from "../images/Mugshots/Mugshot_MadamePotat.png";
import Mugshot_Miner from "../images/Mugshots/Mugshot_Miner.png";
import Mugshot_Pablo from "../images/Mugshots/Mugshot_Pablo.png";
import Mugshot_Paranoid from "../images/Mugshots/Mugshot_Paranoid.png";
import Mugshot_Poppy from "../images/Mugshots/Mugshot_Poppy.png";
import Mugshot_Potatotep from "../images/Mugshots/Mugshot_Potatotep.png";
import Mugshot_Pudding from "../images/Mugshots/Mugshot_Pudding.png";
import Mugshot_Rocker from "../images/Mugshots/Mugshot_Rocker.png";
import Mugshot_Rudoph from "../images/Mugshots/Mugshot_Rudoph.png";
import Mugshot_Santa from "../images/Mugshots/Mugshot_Santa.png";
import Mugshot_Skellybob from "../images/Mugshots/Mugshot_Skellybob.png";
import Mugshot_Spoop from "../images/Mugshots/Mugshot_Spoop.png";
import Mugshot_Spuddington from "../images/Mugshots/Mugshot_Spuddington.png";
import Mugshot_Spudzilla from "../images/Mugshots/Mugshot_Spudzilla.png";
import Mugshot_Werewolf from "../images/Mugshots/Mugshot_Werewolf.png";

const images = [
    chameleonCard,
    ChameleonIcoBlue,
    ChameleoniconGreen,
    Chameleon,
    FillSpace,
    MobileBG,
    SecretWordCard,
    VoteButton,
    YellowBox,
    BG,
    ButtonBG1,
    ButtonBG2,
    ChameleonPodiumLeft2,
    ChameleonPodiumLeft3,
    ChameleonPodiumMiddle,
    ChameleonPodiumRight1,
    ChameleonPodiumRight2,
    ChameleonPodiumRight3,
    characterinfo,
    Correctstamp,
    Logo,
    Postitcopy2,
    Postit,
    Potatonamebar,
    Scoreboard,
    scrawlfullscreen,
    scrawlhelp,
    scrawlmuted,
    scrawlunmuted,
    Votedstamp,
    wordreveal,
    WrongStamp,
    FolderBack,
    FolderCard,
    FolderFront,
    FolderPaperclip,
    FolderRevealCard,
    FolderRound1,
    FolderRound2,
    FolderRound3,
    FolderRound4,
    FolderRound5,
    FolderRound6,
    FolderRound7,
    FolderRound8,
    Mugshot_Bunny,
    Mugshot_Cat,
    Mugshot_Chicken,
    Mugshot_CountBarabula,
    Mugshot_Cowboy,
    Mugshot_EasterEgg,
    Mugshot_Elf,
    Mugshot_FrankensteinsPotato,
    Mugshot_Gingie,
    Mugshot_InvisiblePotato,
    Mugshot_MadamePotat,
    Mugshot_Miner,
    Mugshot_Pablo,
    Mugshot_Paranoid,
    Mugshot_Poppy,
    Mugshot_Potatotep,
    Mugshot_Pudding,
    Mugshot_Rocker,
    Mugshot_Rudoph,
    Mugshot_Santa,
    Mugshot_Skellybob,
    Mugshot_Spoop,
    Mugshot_Spuddington,
    Mugshot_Spudzilla,
    Mugshot_Werewolf,
];

const fonts = [
    { font: "Resistenza", path: `https://${window.location.host}/fonts/Resistenza-PeperoncinoSans.otf` },
    { font: "JenWagner", path: `https://${window.location.host}/fonts/JenWagnerCo-PalmerLakePrintRegular2.otf` },
];

export default {
    images,
    fonts,
};