import potato0 from "images/Avatars/potato-0.png";
import potato1 from "images/Avatars/potato-1.png";
import potato2 from "images/Avatars/potato-2.png";
import potato3 from "images/Avatars/potato-3.png";
import potato4 from "images/Avatars/potato-4.png";
import potato5 from "images/Avatars/potato-5.png";
import potato6 from "images/Avatars/potato-6.png";
import potato7 from "images/Avatars/potato-7.png";
import potato8 from "images/Avatars/potato-8.png";
import potato9 from "images/Avatars/potato-9.png";
import potato10 from "images/Avatars/potato-10.png";
import potato11 from "images/Avatars/potato-11.png";
import potato12 from "images/Avatars/potato-12.png";
import potato13 from "images/Avatars/potato-13.png";
import potato14 from "images/Avatars/potato-14.png";
import potato15 from "images/Avatars/potato-15.png";
import potato16 from "images/Avatars/potato-16.png";
import potato17 from "images/Avatars/potato-17.png";
import potato18 from "images/Avatars/potato-18.png";
import potato19 from "images/Avatars/potato-19.png";
import potato20 from "images/Avatars/potato-20.png";
import potato21 from "images/Avatars/potato-21.png";
import potato22 from "images/Avatars/potato-22.png";
import potato23 from "images/Avatars/potato-23.png";
import potato24 from "images/Avatars/potato-24.png";

import potato0Anim from "animations/avatars/potato-0.json";
import potato1Anim from "animations/avatars/potato-1.json";
import potato2Anim from "animations/avatars/potato-2.json";
import potato3Anim from "animations/avatars/potato-3.json";
import potato4Anim from "animations/avatars/potato-4.json";
import potato5Anim from "animations/avatars/potato-5.json";
import potato6Anim from "animations/avatars/potato-6.json";
import potato7Anim from "animations/avatars/potato-7.json";
import potato8Anim from "animations/avatars/potato-8.json";
import potato9Anim from "animations/avatars/potato-9.json";
import potato10Anim from "animations/avatars/potato-10.json";
import potato11Anim from "animations/avatars/potato-11.json";
import potato12Anim from "animations/avatars/potato-12.json";
import potato13Anim from "animations/avatars/potato-13.json";
import potato14Anim from "animations/avatars/potato-14.json";
import potato15Anim from "animations/avatars/potato-15.json";
import potato16Anim from "animations/avatars/potato-16.json";
import potato17Anim from "animations/avatars/potato-17.json";
import potato18Anim from "animations/avatars/potato-18.json";
import potato19Anim from "animations/avatars/potato-19.json";
import potato20Anim from "animations/avatars/potato-20.json";
import potato21Anim from "animations/avatars/potato-21.json";
import potato22Anim from "animations/avatars/potato-22.json";
import potato23Anim from "animations/avatars/potato-23.json";
import potato24Anim from "animations/avatars/potato-24.json";

import mugshot0 from "images/Mugshots/Mugshot_Chicken.png";
import mugshot1 from "images/Mugshots/Mugshot_Spudzilla.png";
import mugshot2 from "images/Mugshots/Mugshot_Spoop.png";
import mugshot3 from "images/Mugshots/Mugshot_Pablo.png";
import mugshot4 from "images/Mugshots/Mugshot_MadamePotat.png";
import mugshot5 from "images/Mugshots/Mugshot_Spuddington.png";
import mugshot6 from "images/Mugshots/Mugshot_Cowboy.png";
import mugshot7 from "images/Mugshots/Mugshot_Poppy.png";
import mugshot8 from "images/Mugshots/Mugshot_Rocker.png";
import mugshot9 from "images/Mugshots/Mugshot_Miner.png";
import mugshot10 from "images/Mugshots/Mugshot_Cat.png";
import mugshot11 from "images/Mugshots/Mugshot_Paranoid.png";
import mugshot12 from "images/Mugshots/Mugshot_Elf.png";
import mugshot13 from "images/Mugshots/Mugshot_Gingie.png";
import mugshot14 from "images/Mugshots/Mugshot_Pudding.png";
import mugshot15 from "images/Mugshots/Mugshot_Rudoph.png";
import mugshot16 from "images/Mugshots/Mugshot_Santa.png";
import mugshot17 from "images/Mugshots/Mugshot_Bunny.png";
import mugshot18 from "images/Mugshots/Mugshot_EasterEgg.png";
import mugshot19 from "images/Mugshots/Mugshot_CountBarabula.png";
import mugshot20 from "images/Mugshots/Mugshot_FrankensteinsPotato.png";
import mugshot21 from "images/Mugshots/Mugshot_Potatotep.png";
import mugshot22 from "images/Mugshots/Mugshot_Skellybob.png";
import mugshot23 from "images/Mugshots/Mugshot_InvisiblePotato.png";
import mugshot24 from "images/Mugshots/Mugshot_Werewolf.png";

function getOptions(animData) {
    return {
        loop: true,
        autoplay: true,
        prerender: true,
        renderer: "svg",
        animationData: animData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid'
        },
    }
}

function getAvatarById(avId) {
    return avatars.find(x => x.id == avId);
}

const avatars = [
    { id: 0, src: potato0, idleAnim: getOptions(potato0Anim), mugShot: mugshot0, },
    { id: 1, src: potato1, idleAnim: getOptions(potato1Anim), mugShot: mugshot1, },
    { id: 2, src: potato2, idleAnim: getOptions(potato2Anim), mugShot: mugshot2, },
    { id: 3, src: potato3, idleAnim: getOptions(potato3Anim), mugShot: mugshot3, },
    { id: 4, src: potato4, idleAnim: getOptions(potato4Anim), mugShot: mugshot4, },
    { id: 5, src: potato5, idleAnim: getOptions(potato5Anim), mugShot: mugshot5, },
    { id: 6, src: potato6, idleAnim: getOptions(potato6Anim), mugShot: mugshot6, },
    { id: 7, src: potato7, idleAnim: getOptions(potato7Anim), mugShot: mugshot7, },
    { id: 8, src: potato8, idleAnim: getOptions(potato8Anim), mugShot: mugshot8, },
    { id: 9, src: potato9, idleAnim: getOptions(potato9Anim), mugShot: mugshot9, },
    { id: 10, src: potato10, idleAnim: getOptions(potato10Anim), mugShot: mugshot10, },
    { id: 11, src: potato11, idleAnim: getOptions(potato11Anim), mugShot: mugshot11, },
    { id: 12, src: potato12, idleAnim: getOptions(potato12Anim), mugShot: mugshot12, },
    { id: 13, src: potato13, idleAnim: getOptions(potato13Anim), mugShot: mugshot13, },
    { id: 14, src: potato14, idleAnim: getOptions(potato14Anim), mugShot: mugshot14, },
    { id: 15, src: potato15, idleAnim: getOptions(potato15Anim), mugShot: mugshot15, },
    { id: 16, src: potato16, idleAnim: getOptions(potato16Anim), mugShot: mugshot16, },
    { id: 17, src: potato17, idleAnim: getOptions(potato17Anim), mugShot: mugshot17, },
    { id: 18, src: potato18, idleAnim: getOptions(potato18Anim), mugShot: mugshot18, },
    { id: 19, src: potato19, idleAnim: getOptions(potato19Anim), mugShot: mugshot19, },
    { id: 20, src: potato20, idleAnim: getOptions(potato20Anim), mugShot: mugshot20, },
    { id: 21, src: potato21, idleAnim: getOptions(potato21Anim), mugShot: mugshot21, },
    { id: 22, src: potato22, idleAnim: getOptions(potato22Anim), mugShot: mugshot22, },
    { id: 23, src: potato23, idleAnim: getOptions(potato23Anim), mugShot: mugshot23, },
    { id: 24, src: potato24, idleAnim: getOptions(potato24Anim), mugShot: mugshot24, },
];

export default getAvatarById;