const animation = require("./Speech Bubble_Captain.json");

module.exports = {
    loop: false,
    autoplay: true,
    prerender: true,
    renderer: "svg",
    animationData: animation,
    rendererSettings: {
        preserveAspectRatio: 'xMidyMid'
    },
}